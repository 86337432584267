<template>
  <v-card color="transparent" class="pt-0 elevation-0 expande-horizontal wrap">
    <div class="expande-horizontal pb-0">
      <v-flex xs12 md8>
        <v-flex xs12>
          <v-form ref="form">
            <v-timeline align-top dense>
              <v-timeline-item icon="mdi-image">
                <v-list-item class="pt-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte blue--text">
                      Insira a logo do seu negócio
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Click no botão "Add" e escolha uma foto para o seu perfil!
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex xs12 class="px-1">
                  <ModalSendArchive :unique="true" />
                </v-flex>
              </v-timeline-item>

              <v-timeline-item icon="mdi-account-circle">
                <v-list-item class="pt-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte blue--text">
                      Fale sobre o seu negócio (Opcional)
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Escreve uma breve descrição para seus clientes.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex xs12 class="px-1">
                  <v-flex class="px-3">
                    <v-textarea
                      solo-inverted
                      flat
                      background-color="#dbdbdb"
                      height="80"
                      placeholder="Escreva aqui..."
                      v-model="get_linkeqrcode.descricao"
                    ></v-textarea>
                  </v-flex>
                </v-flex>
              </v-timeline-item>

              <v-timeline-item icon="mdi-whatsapp">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fonte blue--text">
                      Faça uma lista de links úteis para o seu cliente
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      São links que levam seus clientes ao seu whatsapp ou redes
                      sociais.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-flex class="px-3" xs12>
                  <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                    Nome
                  </span>
                  <v-text-field
                    tile
                    v-model="get_linkeqrcode.nome"
                    dense
                    solo-inverted
                    flat
                    clearable
                    :color="$theme.primary"
                    :rules="[v => !!v || 'Preencha este campo']"
                    label="ex: Roberto"
                  ></v-text-field>
                </v-flex> -->
              </v-timeline-item>
            </v-timeline>
          </v-form>
        </v-flex>
        <div class="expande-horizontal px-6">
          <v-btn text rounded dark @click="valida_form" class="green">
            <span class="fonte"> Salvar </span>
          </v-btn>
        </div>
      </v-flex>
      <v-flex class="px-3" xs12 md4>
        <div class="cel">
          <div class="expande-horizontal centraliza column">
            <v-avatar size="80" class="mt-9">
              <v-img :src="getLinks[0]"></v-img>
            </v-avatar>
            <h3
              class="fonte white--text text-center px-4 mt-6 mb-6"
              style="font-size: 9pt; font-weight: 500"
            >
              {{ get_linkeqrcode.descricao }}
            </h3>
            <div class="expande-horizontal centraliza">
              <v-btn
                @click="setRetirada(!get_linkeqrcode.retirada)"
                :color="get_linkeqrcode.retirada ? 'green' : 'red'"
                dark
                class="elevation-0 mr-2"
                x-small
              >
                retirada
                <v-icon class="ml-1" size="13" v-if="get_linkeqrcode.retirada"
                  >mdi-check-circle</v-icon
                >
                <v-icon class="ml-1" size="13" v-else>
                  mdi-close-circle
                </v-icon>
              </v-btn>
              <v-btn
                @click="setDelivery(!get_linkeqrcode.delivery)"
                :color="get_linkeqrcode.delivery ? 'green' : 'red'"
                dark
                class="elevation-0"
                x-small
              >
                delivery
                <v-icon class="ml-1" size="13" v-if="get_linkeqrcode.delivery"
                  >mdi-check-circle</v-icon
                >
                <v-icon class="ml-1" size="13" v-else>
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </div>
            <div class="expande-horizontal wrap">
              <v-flex class="pa-6" xs12 v-if="get_linkeqrcode.links.length">
                <v-list color="transparent" class="pa-0 ma-0">
                  <template v-for="(item, index) in get_linkeqrcode.links">
                    <v-list-item
                      @click="openLink(item)"
                      class="item-list mb-1"
                      :key="index"
                    >
                      <v-icon color="green" size="18">mdi-chevron-right</v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="fonte">
                          {{ item.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
              <v-flex xs12 v-else class="pa-3">
                <div
                  style="border: 1px solid #333; border-radius: 6px;"
                  class="expande-horizontal centraliza pa-3 fonte"
                >
                  <h5 class="text-center">
                    Você ainda não adicionou links, adicione seu primeiro link
                    abaixo!
                  </h5>
                </div>
              </v-flex>
              <v-flex class="pa-6" xs12>
                <div class="expande-horizontal centraliza">
                  <v-btn
                    @click="abre_modal_view_linkeqrcode"
                    class="elevation-0"
                    rounded
                    block
                    small
                  >
                    <span class="fonte font-weight-bold">Adicionar Link</span>
                    <v-icon size="18">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  data() {
    return {
      novo_link_dialog: false,
      novo_link_content: {}
    };
  },
  computed: {
    ...mapGetters(["get_linkeqrcode", "getLinks"])
  },
  methods: {
    ...mapActions(["atualizar_funcionario", "abre_modal_view_linkeqrcode"]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_linkeqrcode._id
          ? this.atualizar_funcionario()
          : this.criar_funcionario();
      }
    },
    setRetirada(val) {
      this.$store.commit("set_linkeqrcode", {
        ...this.get_linkeqrcode,
        retirada: val
      });
    },
    setDelivery(val) {
      this.$store.commit("set_linkeqrcode", {
        ...this.get_linkeqrcode,
        delivery: val
      });
    },
    openLink(item) {
      window.open(item.link, "_blank");
    }
  },
  created() {
    if (!this.get_linkeqrcode.retirada) {
      this.get_linkeqrcode.retirada = false;
    }
    if (!this.get_linkeqrcode.delivery) {
      this.get_linkeqrcode.delivery = false;
    }
    if (!this.get_linkeqrcode.links) {
      this.get_linkeqrcode.links = [];
    }
    // this.$store.commit("setLinks", this.get_linkeqrcode.photo);
  }
};
</script>

<style>
.cel {
  width: 300px;
  height: 620px;
  border-right: 2px solid #333;
  border-left: 2px solid #333;
  border-radius: 6px;
  border-top: 12px solid #333;
  border-bottom: 18px solid #333;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #c480ff, #8e2de2);
}
.item-list {
  border-radius: 6px;
  background-color: #fff;
}
</style>

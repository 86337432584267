<template>
  <layout :title="$route.name">
    <Form class="pa-3" v-if="tabs === 0" />
    <ModalView />
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "../components/Form";
import ModalView from "../components/modalView";
export default {
  data() {
    return {
      tabs: 0
    };
  },
  components: {
    Form,
    ModalView
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  created() {
    this.$run("marketplaces/list-dashboard")
      .then(res => {
        console.log("teste", res);
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>
